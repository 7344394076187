import Correspondence from '@/models/Correspondence';
import VariationOrder from '@/models/VariationOrder';
import VariationOrderService from '@/services/VariationOrderService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';
import { useCorrespondencesStore } from './correspondences';
import { useTradeItemsStore } from './tradeItems';

export const useVariationOrdersStore = defineStore('variation_orders', {
  state: () => ({}),

  getters: {
    models: () => useRepo(VariationOrder),
  },

  actions: {
    async fetchVariationOrders(projectId: Id, form: ApiRequests['api.v1.variation-orders.index'] = {}) {
      const [data, error] = await VariationOrderService.index(projectId, form);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async fetchApprovedVariationOrders(projectId: Id) {
      const [data, error] = await VariationOrderService.claimIndex(projectId);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.show(id);

      if(data) {
        this.models.save(data.data);

        return data;
      }

      throw error;
    },

    async acceptVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.accept(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async declineVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.decline(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },

    async priceVariationOrder(id: Id, form: ApiRequests['api.v1.variation-orders.pricing']) {
      const [data, error] = await VariationOrderService.price(id, form);

      if(data) {
        const tradeItemsStore = useTradeItemsStore();

        tradeItemsStore.models.where('variation_order_id', parseInt(id as string)).delete();

        this.models.save(data);

        return data;
      }

      throw error;
    },

    async submitVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.submit(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async provisionVariationOrder(id: Id, signedParams: unknown) {
      const [data, error] = await VariationOrderService.provision(id, signedParams);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async approveVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.approve(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async completeVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.complete(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async createVariationOrder(projectId: Id, request: ApiRequests['api.v1.variation-orders.store'] | FormData) {
      const [data, error] = await VariationOrderService.create(projectId, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateVariationOrder(id: Id, request: ApiRequests['api.v1.variation-orders.update']) {
      const [data, error] = await VariationOrderService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async sendNoticeForVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.sendNotice(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteVariationOrder(id: Id) {
      const [data, error] = await VariationOrderService.delete(id);

      if(data) {
        this.models.destroy(data.id);

        return data;
      }

      throw error;
    },

    async fetchVariationOrdersSummary(projectId: Id) {
      const [data, error] = await VariationOrderService.summary(projectId);

      if(data) {
        return data as VariationOrdersSummary;
      }

      throw error;
    },

    async comment(id: Id, form: ApiRequests['api.v1.variation-orders.comments']) {
      const [data, error] = await VariationOrderService.comment(id, form);

      if(data) {
        // TODO: 28/02/25 Save comment?

        return data;
      }

      throw error;
    },

    async media(id: Id) {
      const [data, error] = await VariationOrderService.media(id);

      if(data) {
        return data;
      }

      throw error;
    },

    async storeMedia(id: Id, form: ApiRequests['api.v1.claims.store-media'] | FormData) {
      const [data, error] = await VariationOrderService.storeMedia(id, form);

      if(data) {
        return data;
      }

      throw error;
    },

    async storeNotice(id: Id, form: ApiRequests['api.v1.variation-orders.notice.store'] | FormData) {
      const [data, error] = await VariationOrderService.notice.store(id, form);

      if(data) {
        this.models.save(data.variation_order);

        return data;
      }

      throw error;
    },

    async storePricingNotice(id: Id, form: ApiRequests['api.v1.variation-orders.pricing-notice.store'] | FormData) {
      const [data, error] = await VariationOrderService.pricingNotice.store(id, form);

      if(data) {
        this.models.save(data.variation_order);

        return data;
      }

      throw error;
    },

    async fetchCorrespondences(id: Id) {
      const correspondencesStores = useCorrespondencesStore();
      const [data, error] = await VariationOrderService.correspondence.index(id);

      if(data) {
        correspondencesStores.models.save(data);

        return data;
      }

      throw error;
    },

    async storeCorrespondence(id: Id, form: ApiRequests['api.v1.variation-orders.correspondence.store'] | FormData) {
      const correspondencesStores = useCorrespondencesStore();
      const [data, error] = await VariationOrderService.correspondence.store(id, form);

      if(data) {
        console.log(data.correspondence);

        correspondencesStores.models.save(data.correspondence);

        return data;
      }

      throw error;
    },
  },
});
