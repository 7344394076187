import { Model, useRepo } from 'pinia-orm';
import { Attr, BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';
import Claim from './Claim';
import Trade from './Trade';
import TradeItemCost from './TradeItemCost';
/* --- user header --- */
/* --- end user header --- */

export default class TradeItem extends Model {
  static entity = 'App\\Models\\TradeItem';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare name: string;
  @Num(0)
  declare budget: number;
  @Num(0)
  declare quantity: number;
  @Num(0)
  declare rate: number;
  @Num(0)
  declare awarded_value: number;
  @Str('')
  declare unit: string;
  @Num(0)
  declare sort_order: number;
  @Attr(undefined)
  declare bill_item: number | undefined;
  @Num(undefined)
  declare trade_id: number | undefined;
  @Num(undefined)
  declare variation_order_id: number | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Attr(undefined)
  declare $previous: number | undefined;
  // relations
  @BelongsTo(() => Trade, 'trade_id', 'id')
  declare trade: Trade;
  @HasMany(() => TradeItemCost, 'trade_item_id', 'id')
  declare trade_item_costs: TradeItemCost[];
  /* --- user code --- */
  @Attr(undefined)
  declare $totalCertified: number | undefined;
  @Attr(undefined)
  declare $totalClaim: number | undefined;

  public previous(claim: Claim) {
    if(this.$previous !== undefined) {
      return this.$previous;
    }

    const previousCosts = useRepo(TradeItemCost)
      .where('trade_item_id', this.id)
      .whereHas('claim', (query) => {
        query.where('id', (id) => {
          return id !== claim.id;
        })
          .where('end', (end) => {
            return end < claim.end;
          });
      }).get();

    let totalPrevious = 0;

    previousCosts.forEach((cost) => {
      totalPrevious += cost.current_certified;
    });

    return totalPrevious;
  }

  get billNumber() {
    return this.bill_item ?? this.sort_order;
  }

  /* --- end user code --- */
}
