import Correspondence from '@/models/Correspondence';
import CorrespondenceService from '@/services/CorrespondenceService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useCorrespondencesStore = defineStore('correspondences', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Correspondence),
  },

  actions: {
    async fetchMedia(id: Id) {
      const [data, error] = await CorrespondenceService.showMedia(id);

      if(data) {
        return data;
      }

      throw error;
    },
  },
});
