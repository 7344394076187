import { DateTime } from 'luxon';
import { Model } from 'pinia-orm';
import { Bool, MorphTo, Num, Str } from 'pinia-orm/dist/decorators';
import LabourDailyRecord from './LabourDailyRecord';
import PlantDailyRecord from './PlantDailyRecord';
import User from './User';
import VariationOrder from './VariationOrder';
/* --- user header --- */
/* --- end user header --- */

export default class Correspondence extends Model {
  static entity = 'App\\Models\\Correspondence';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare description: string;
  @Str('')
  declare to: string;
  @Str('')
  declare from: string;
  @Str('')
  declare correspondent_type: string;
  @Num(0)
  declare correspondent_id: number;
  @Str('')
  declare type: string;
  @Bool(false)
  declare response_required: boolean;
  @Str('')
  declare created_at: string;
  @Str('')
  declare updated_at: string;
  @Str('')
  declare date_received: string;
  // relations
  @MorphTo(() => [VariationOrder], 'correspondent_id', 'correspondent_type')
  declare correspondent: VariationOrder;
  /* --- user code --- */

  public get createdAt(): DateTime {
    return this.created_at ? DateTime.fromISO(this.created_at) : undefined;
  }

  public get dateReceived(): DateTime {
    return this.date_received ? DateTime.fromISO(this.date_received) : undefined;
  }

  /* --- end user code --- */
}
