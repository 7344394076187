import { falsey } from '@/helpers';
import { DateTime } from 'luxon';
import { Model } from 'pinia-orm';
import { Attr, BelongsTo, Bool, HasMany, HasManyThrough, Num, Str } from 'pinia-orm/dist/decorators';
import Project from './Project';
import Trade from './Trade';
import TradeItemCost from './TradeItemCost';
import VariationOrder from './VariationOrder';
import VariationOrderCost from './VariationOrderCost';
/* --- user header --- */
/* --- end user header --- */

export const ClaimStatus = {
  draft: 0,
  draft_pqs: 1,
  draft_contractor: 2,
  contractor_claim: 3,
  pqs_review: 4,
  certified: 5,
} as const;

export const ClaimStatusTitles = {
  draft: 'Draft',
  draft_pqs: 'Draft Issued to PQS',
  draft_contractor: 'Draft Issued to Contractor',
  contractor_claim: 'Draft Issued to Contractor',
  pqs_review: 'Claim Submitted',
  certified: 'Certified',
};

export default class Claim extends Model {
  static entity = 'App\\Models\\Claim';
  // fields
  @Num(0)
  declare id: number;
  @Num(0)
  declare project_id: number;
  @Str('')
  declare start: string;
  @Str('')
  declare end: string;
  @Num(0)
  declare retention: number;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Num(0)
  declare claim_number: number;
  @Bool(true)
  declare capture_claim_amount: boolean;
  @Num(0)
  declare status: ObjectValues<typeof ClaimStatus>;
  @Str('')
  declare submitted_at_time_zone: string | undefined;
  @Str('')
  declare submitted_at: string | undefined;
  @Str('')
  declare draft_issued_at_time_zone: string | undefined;
  @Str('')
  declare draft_issued_at: string | undefined;
  @Str('')
  declare claim_issued_at_time_zone: string | undefined;
  @Str('')
  declare claim_issued_at: string | undefined;
  @Str('')
  declare boq_type: string | undefined;
  @Bool(undefined)
  declare stat_dec_received: boolean | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @HasManyThrough(() => Trade, () => Project, 'id', 'project_id', 'project_id', 'id')
  declare trades: Trade[];
  @HasManyThrough(() => VariationOrder, () => Project, 'id', 'project_id', 'project_id', 'id')
  declare variation_orders: VariationOrder[];
  @HasMany(() => TradeItemCost, 'claim_id', 'id')
  declare trade_item_costs: TradeItemCost[];
  @HasMany(() => VariationOrderCost, 'claim_id', 'id')
  declare variation_order_costs: VariationOrderCost[];
  /* --- user code --- */

  // Computed Properties
  @Attr(undefined)
  declare $total_claim: number | undefined;
  @Attr(undefined)
  declare $total_certified: number | undefined;
  @Attr(undefined)
  declare $total_budget: number | undefined;

  get totalClaim(): number {
    return this.$total_claim ||
      this.trade_item_costs?.reduce((total, value) => total + value.current_claim, 0) +
        this.variation_order_costs?.reduce((total, value) => total + value.current_claim, 0) ||
      0;
  }

  get totalCertified(): number {
    return this.$total_certified ||
      this.trade_item_costs?.reduce((total, value) => total + value.current_certified, 0) +
        this.variation_order_costs?.reduce((total, value) => total + value.current_certified, 0) ||
      0;
  }

  get totalBudget(): number {
    if(!falsey(this.$total_budget)) {
      return this.$total_budget;
    }

    return this.trade_item_costs?.reduce((total, value) => total + value.current_certified, 0) +
        this.variation_order_costs?.reduce((total, value) => total + value.current_certified, 0) ||
      0;
  }

  get totalVariance(): number {
    return this.totalClaim - this.totalCertified;
  }

  get draftIssuedAt() {
    return this.draft_issued_at ?
      DateTime.fromISO(this.draft_issued_at).setZone(this.draft_issued_at_time_zone) :
      undefined;
  }

  get claimIssuedAt() {
    return this.claim_issued_at ?
      DateTime.fromISO(this.claim_issued_at).setZone(this.claim_issued_at_time_zone) :
      undefined;
  }

  get submittedAt() {
    return this.submitted_at ? DateTime.fromISO(this.submitted_at).setZone(this.submitted_at_time_zone) : undefined;
  }

  get createdAt() {
    return this.created_at ? DateTime.fromISO(this.created_at) : undefined;
  }

  get startDate() {
    return this.start ? DateTime.fromISO(this.start) : undefined;
  }

  get endDate() {
    return this.end ? DateTime.fromISO(this.end) : undefined;
  }
  /* --- end user code --- */
}
