import { enumKeyFromValue } from '@/helpers';
import { DateTime } from 'luxon';
import { Model } from 'pinia-orm';
import { BelongsTo, HasOne, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import Comment from './Comment';
import Project from './Project';
import User from './User';
import VariationOrder from './VariationOrder';
/* --- user header --- */
/* --- end user header --- */

export const DelayStatus = {
  raised: 0,
  registered: 1,
  submitted: 2,
  provisioned: 3,
  approved: 4,
} as const;

export const DelayStatusTitles = {
  raised: 'Raised',
  registered: 'Registered',
  submitted: 'Submitted',
  provisioned: 'Provisioned',
  approved: 'Approved',
} as const;

export const DelayStatusColours = {
  raised: 'rgb(225, 22, 32)',
  registered: 'rgb(0, 123, 219)',
  submitted: 'rgb(165, 165, 165)',
  provisioned: 'rgb(248, 96, 19)',
  approved: 'rgb(22, 206, 12)',
} as const;

export default class Delay extends Model {
  static entity = 'App\\Models\\Delay';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare code: string;
  @Str('')
  declare description: string;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare user_id: number;
  @Str('')
  declare deleted_at: string | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Str('')
  declare registered_at: string | undefined;
  @Str('')
  declare submitted_at: string | undefined;
  @Str('')
  declare provisioned_at: string | undefined;
  @Str('')
  declare approved_at: string | undefined;
  @Str(undefined)
  declare notes: string | undefined;
  @Str(undefined)
  declare client_ref: string | undefined;
  @Num(undefined)
  declare days: number | undefined;
  @Num(undefined)
  declare status: ObjectValues<typeof DelayStatus>;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @BelongsTo(() => User, 'user_id', 'id')
  declare user: User;
  @HasOne(() => VariationOrder, 'delay_id', 'id')
  declare variation_order: VariationOrder;
  @MorphMany(() => Comment, 'commentable_id', 'commentable_type', 'id')
  declare comments: Comment[];
  /* --- user code --- */

  get statusTitle() {
    return DelayStatusTitles[enumKeyFromValue(DelayStatus, this.status)];
  }

  get createdAt() {
    return this.created_at ? DateTime.fromISO(this.created_at) : undefined;
  }
  get submittedAt() {
    return this.submitted_at ? DateTime.fromISO(this.submitted_at) : undefined;
  }
  get provisionedAt() {
    return this.provisioned_at ? DateTime.fromISO(this.provisioned_at) : undefined;
  }
  get approvedAt() {
    return this.approved_at ? DateTime.fromISO(this.approved_at) : undefined;
  }
  get registeredAt() {
    return this.registered_at ? DateTime.fromISO(this.registered_at) : undefined;
  }
  /* --- end user code --- */
}
