import { enumKeyFromValue, falsey } from '@/helpers';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Attr, BelongsTo, HasMany, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import Claim from './Claim';
import Comment from './Comment';
import Correspondence from './Correspondence';
import Delay from './Delay';
import Project from './Project';
import TradeItem from './TradeItem';
import User from './User';
import VariationOrderCost from './VariationOrderCost';
/* --- user header --- */
/* --- end user header --- */

export const VariationOrderType = {
  variation: 0,
  provisional_sum: 1,
  time_extension: 2,
  other: 3,
} as const;

export const VariationOrderStatus = {
  raised: 0,
  registered: 1,
  priced: 2,
  submitted: 3,
  provisioned: 4,
  approved: 5,
  completed: 6,
  declined: 7,
} as const;

export const VariationOrderTitles = {
  variation: 'Variations',
  provisional_sum: 'Provisional Sums',
  time_extension: 'Extension of Time',
  other: 'Other',
} as const;

export const VariationOrderStatusTitles = {
  raised: 'Raised',
  registered: 'Registered',
  priced: 'Priced',
  submitted: 'Submitted',
  provisioned: 'Provisioned',
  approved: 'Approved',
  completed: 'Completed',
  declined: 'Declined',
} as const;

export const VariationOrderStatusColours = {
  raised: '#B80D15',
  registered: '#A6ACEC',
  priced: '#4E95D9',
  submitted: '#215F9A',
  provisioned: '#163E64',
  approved: '#0E2841',
  completed: '#196B24',
  declined: '#000',
} as const;

export const VariationOrderStatusTextColours = {
  raised: '#FFF',
  registered: '#000',
  priced: '#000',
  submitted: '#000',
  provisioned: '#FFF',
  approved: '#FFF',
  completed: '#FFF',
  declined: '#000',
} as const;

export default class VariationOrder extends Model {
  static entity = 'App\\Models\\VariationOrder';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare code: string;
  @Str('')
  declare description: string;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare user_id: number;
  @Num(0)
  declare delay_id: number;
  @Str('')
  declare deleted_at: string | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Str('')
  declare registered_at: string | undefined;
  @Str('')
  declare submitted_at: string | undefined;
  @Str('')
  declare provisioned_at: string | undefined;
  @Str('')
  declare approved_at: string | undefined;
  @Str('')
  declare notice_sent_at: string | undefined;
  @Str('')
  declare date_of_occurrence: string | undefined;
  @Str(undefined)
  declare notes: string | undefined;
  @Str(undefined)
  declare next_action: string | undefined;
  @Str(undefined)
  declare client_ref: string | undefined;
  @Num(undefined)
  declare budget: number | undefined;
  @Num(undefined)
  declare type: ObjectValues<typeof VariationOrderType>;
  @Num(undefined)
  declare status: ObjectValues<typeof VariationOrderStatus>;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @BelongsTo(() => Delay, 'delay_id', 'id')
  declare delay: Delay;
  @HasMany(() => VariationOrderCost, 'variation_order_id', 'id')
  declare costs: VariationOrderCost[];
  @HasMany(() => TradeItem, 'variation_order_id', 'id')
  declare trade_items: TradeItem[];
  @BelongsTo(() => User, 'user_id', 'id')
  declare user: User;
  @MorphMany(() => Comment, 'commentable_id', 'commentable_type', 'id')
  declare comments: Comment[];
  @MorphMany(() => Correspondence, 'correspondent_id', 'correspondent_type', 'id')
  declare correspondences: Correspondence[];
  @Attr(undefined)
  declare $previous: number | undefined;
  @Attr(undefined)
  declare $totalCertified: number | undefined;
  @Attr(undefined)
  declare $totalClaim: number | undefined;
  /* --- user code --- */

  get totalBudget() {
    return this.budget ?? this.trade_items?.reduce((total, tradeItem) => {
      return total + tradeItem.budget;
    }, 0) ?? 0;
  }

  get statusTitle() {
    return VariationOrderStatusTitles[enumKeyFromValue(VariationOrderStatus, this.status)];
  }

  get statusColour() {
    return VariationOrderStatusColours[enumKeyFromValue(VariationOrderStatus, this.status)];
  }

  get statusTextColour() {
    return VariationOrderStatusTextColours[enumKeyFromValue(VariationOrderStatus, this.status)];
  }

  public previous(claim: Claim) {
    if(!falsey(this.$previous)) {
      return this.$previous;
    }

    const previousCosts = useRepo(VariationOrderCost)
      .where('variation_order_id', this.id)
      .whereHas('claim', (query) => {
        query.where('id', (id) => {
          return id !== claim.id;
        })
          .where('end', (end) => {
            return end < claim.end;
          });
      }).get();

    let totalPrevious = 0;

    previousCosts.forEach((cost) => {
      totalPrevious += cost.current_certified;
    });

    return totalPrevious;
  }

  get totalClaim() {
    return this.$totalClaim;
  }

  get totalCertified() {
    return this.$totalCertified;
  }

  get createdAt() {
    return this.created_at ? DateTime.fromISO(this.created_at) : undefined;
  }
  get submittedAt() {
    return this.submitted_at ? DateTime.fromISO(this.submitted_at) : undefined;
  }
  get provisionedAt() {
    return this.provisioned_at ? DateTime.fromISO(this.provisioned_at) : undefined;
  }
  get approvedAt() {
    return this.approved_at ? DateTime.fromISO(this.approved_at) : undefined;
  }
  get registeredAt() {
    return this.registered_at ? DateTime.fromISO(this.registered_at) : undefined;
  }
  get noticeSentAt() {
    return this.notice_sent_at ? DateTime.fromISO(this.notice_sent_at) : undefined;
  }
  get dateOfOccurrence() {
    return this.date_of_occurrence ? DateTime.fromISO(this.date_of_occurrence) : undefined;
  }
  /* --- end user code --- */
}
