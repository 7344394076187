import { falsey } from '@/helpers';
import { DateTime } from 'luxon';
import { Model } from 'pinia-orm';
import { Attr, BelongsTo, HasMany, HasManyThrough, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import Comment from './Comment';
import Project from './Project';
import TradeItem from './TradeItem';
import TradeItemCost from './TradeItemCost';
/* --- user header --- */
/* --- end user header --- */

export default class Trade extends Model {
  static entity = 'App\\Models\\Trade';
  // fields
  @Num(0)
  declare id: number;
  @Str('')
  declare name: string;
  @Num(0)
  declare project_id: number;
  @Num(0)
  declare sort_order: number;
  @Attr(undefined)
  declare bill_item: number | undefined;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Str('')
  declare baseline_start_date: string | undefined;
  @Str('')
  declare baseline_end_date: string | undefined;
  @Str('')
  declare forecast_start_date: string | undefined;
  @Str('')
  declare forecast_end_date: string | undefined;
  @Str('')
  declare boq_type: string | undefined;
  @Attr(undefined)
  declare baseline_forecast: number[] | undefined;
  @Attr(undefined)
  declare $total_budget: number | undefined;
  @Attr(undefined)
  declare forecast: number[] | undefined;
  @Attr(undefined)
  declare actuals_filled: boolean[] | undefined;
  // relations
  @BelongsTo(() => Project, 'project_id', 'id')
  declare project: Project;
  @HasMany(() => TradeItem, 'trade_id', 'id')
  declare trade_items: TradeItem[];
  @HasManyThrough(() => TradeItemCost, () => TradeItem, 'trade_id', 'trade_item_id', 'id', 'id')
  declare trade_item_costs: TradeItemCost[];
  @MorphMany(() => Comment, 'commentable_id', 'commentable_type', 'id')
  declare comments: Comment[];
  /* --- user code --- */

  get totalClaim() {
    return this.trade_items.reduce((total, tradeItem) => {
      if(!falsey(tradeItem.$totalClaim)) {
        return total += tradeItem.$totalClaim;
      }

      return total;
    }, 0);
  }

  get totalCertified() {
    return this.trade_items.reduce((total, tradeItem) => {
      if(!falsey(tradeItem.$totalCertified)) {
        return total += tradeItem.$totalCertified;
      }

      return total;
    }, 0);
  }

  get totalBudget() {
    const budget = this.trade_items?.reduce((total, tradeItem) => {
      if(!falsey(tradeItem.budget)) {
        return total += tradeItem.budget;
      }

      return total;
    }, 0) ?? 0;

    return budget === 0 ? this.$total_budget : budget;
  }

  get totalForecastActuals() {
    return this.forecast?.reduce((total, forecast, i) => {
      if(this.actuals_filled[i]) {
        total += forecast;
      }

      return total;
    }, 0) ?? 0;
  }

  get billNumber() {
    return this.bill_item ?? this.sort_order;
  }

  get baselineDuration() {
    if(this.baseline_start_date && this.baseline_end_date) {
      const start = DateTime.fromFormat(this.baseline_start_date, 'yyyy-MM-dd').startOf('month');
      const end = DateTime.fromFormat(this.baseline_end_date, 'yyyy-MM-dd').endOf('month');

      return Math.ceil(Math.abs(start.diff(end, ['months']).months)) + (start.day >= end.day ? 1 : 0);
    }

    return undefined;
  }

  get baselineStart() {
    if(this.baseline_start_date) {
      return DateTime.fromFormat(this.baseline_start_date, 'yyyy-MM-dd').startOf('month');
    }

    return undefined;
  }

  get baselineEnd() {
    if(this.baseline_end_date) {
      return DateTime.fromFormat(this.baseline_end_date, 'yyyy-MM-dd').endOf('month');
    }

    return undefined;
  }

  get forecastDuration() {
    if(this.forecast_start_date && this.forecast_end_date) {
      const start = DateTime.fromFormat(this.forecast_start_date, 'yyyy-MM-dd').startOf('month');
      const end = DateTime.fromFormat(this.forecast_end_date, 'yyyy-MM-dd').endOf('month');

      return Math.ceil(Math.abs(start.diff(end, ['months']).months)) + (start.day >= end.day ? 1 : 0);
    }

    return undefined;
  }

  get forecastStart() {
    if(this.forecast_start_date) {
      return DateTime.fromFormat(this.forecast_start_date, 'yyyy-MM-dd').startOf('month');
    }

    return undefined;
  }

  get forecastEnd() {
    if(this.forecast_end_date) {
      return DateTime.fromFormat(this.forecast_end_date, 'yyyy-MM-dd').endOf('month');
    }

    return undefined;
  }

  get combinedDuration() {
    if(this.combinedStart && this.combinedEnd) {
      return Math.ceil(Math.abs(this.combinedStart.diff(this.combinedEnd, ['months']).months)) +
        (this.combinedStart.day >= this.combinedEnd.day ? 1 : 0);
    }

    return undefined;
  }

  get combinedStart() {
    if(this.forecast_start_date && this.baseline_start_date) {
      if(this.forecast_start_date < this.baseline_start_date) {
        return DateTime.fromFormat(this.forecast_start_date, 'yyyy-MM-dd').startOf('month');
      } else {
        return DateTime.fromFormat(this.baseline_start_date, 'yyyy-MM-dd').startOf('month');
      }
    }

    if(this.baseline_start_date) {
      return DateTime.fromFormat(this.baseline_start_date, 'yyyy-MM-dd').startOf('month');
    }

    if(this.forecast_start_date) {
      return DateTime.fromFormat(this.forecast_start_date, 'yyyy-MM-dd').startOf('month');
    }

    return undefined;
  }

  get combinedEnd() {
    if(this.forecast_end_date && this.baseline_end_date) {
      if(this.forecast_end_date > this.baseline_end_date) {
        return DateTime.fromFormat(this.forecast_end_date, 'yyyy-MM-dd').endOf('month');
      } else {
        return DateTime.fromFormat(this.baseline_end_date, 'yyyy-MM-dd').endOf('month');
      }
    }

    if(this.baseline_end_date) {
      return DateTime.fromFormat(this.baseline_end_date, 'yyyy-MM-dd').endOf('month');
    }

    if(this.forecast_end_date) {
      return DateTime.fromFormat(this.forecast_end_date, 'yyyy-MM-dd').endOf('month');
    }

    return undefined;
  }

  /* --- end user code --- */
}
