import { reactive, ref, watch } from 'vue';

type Join<K, P> = `${K & string}${'' extends P ? '' : '.'}${'' extends P ? '' : P & string}`;

type Leaves<T> = T extends (infer U)[] ? `${number}${U extends object ? `.${Leaves<U>}` : ''}` :
  T extends object ? { [K in keyof T]-?: Join<K, Leaves<T[K]>> }[keyof T] :
  '';

type LeavesExcludeArray<T> = T extends (infer _U)[] ? '' :
  T extends object ? { [K in keyof T]-?: Join<K, LeavesExcludeArray<T[K]>> }[keyof T] :
  '';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object ? RecursivePartial<T[P]> :
    T[P];
};

function flattenObject(obj: unknown, prefix = '') {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';

    if(typeof obj[k] === 'object') Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];

    return acc;
  }, {});
}

function recurseKeys(obj: unknown, permittedKeys: string[], dotKey?: string) {
  for(const [key, value] of Object.entries(obj)) {
    if(typeof value === 'object') {
      obj[key] = recurseKeys(value, permittedKeys, dotKey ? dotKey + `.${key}` : key);
    } else if(!permittedKeys.includes(dotKey ? dotKey + `.${key}` : key)) {
      delete obj[key];
    }
  }

  return obj;
}

function isObject(item: unknown) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

function mergeDeep(target: unknown, ...sources: any[]) {
  if(!sources.length) return target;
  const source = sources.shift();

  if(isObject(target) && isObject(source)) {
    for(const key in source) {
      if(isObject(source[key])) {
        if(!target[key]) {
          Object.assign(target, { [key]: {} });
        } else {
          target[key] = { ...target[key] };
        }

        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

function recursiveSet(target: any, source: any, currentKey: string = undefined, excludeKeys: string[] = undefined) {
  for(const key in target) {
    if(key in source) {
      if(isObject(target[key]) && isObject(source[key])) {
        recursiveSet(target[key], source[key], currentKey ? currentKey + `.${key}` : key, excludeKeys);
      } else if(
        !isObject(target[key]) &&
        !isObject(source[key]) &&
        !excludeKeys.includes(currentKey ? currentKey + `.${key}` : key)
      ) {
        target[key] = source[key];
      }
    }
  }
}

export function useFormData<T extends Record<string, unknown>>(initialState: T, dirty = false) {
  const form = reactive<T>({ ...initialState });
  const errorState = {};

  for(const [key, _value] of Object.entries(flattenObject(initialState))) {
    errorState[key] = [];
  }

  type NestedObjectPaths = Leaves<typeof initialState>;
  type NestedExcludingArray = LeavesExcludeArray<typeof initialState>;

  const errors = reactive({ ...errorState }) as {
    [K in NestedObjectPaths]: string[];
  };

  const isDirty = ref(dirty);

  if(!isDirty.value) {
    watch(
      form,
      (newValue) => {
        isDirty.value = true;
      },
      { once: true },
    );
  }

  const resetDirtyStatus = () => {
    isDirty.value = false;

    watch(
      form,
      (newValue) => {
        isDirty.value = true;
      },
      { once: true },
    );
  };

  const resetData = (keys: (keyof {
    [K in NestedExcludingArray]: string[];
  })[] = []) => {
    const specifiedState = structuredClone(initialState);

    if(keys.length > 0) {
      recurseKeys(specifiedState, keys);
    }

    mergeDeep(form, specifiedState);

    resetDirtyStatus();
  };

  const setData = (data: RecursivePartial<T>, excludeKeys: (keyof {
    [K in NestedExcludingArray]: string[];
  })[] = []) => {
    recursiveSet(form, data, undefined, excludeKeys);

    resetDirtyStatus();
  };

  const setErrors = (newErrors: {
    [k in keyof T]: string[];
  }) => {
    if(
      newErrors &&
      typeof newErrors === 'object' &&
      'errors' in newErrors
    ) {
      for(const [key, value] of Object.entries(newErrors.errors)) {
        errors[key] = value;
      }
    }
  };

  const getErrors = (key: keyof {
    [K in NestedObjectPaths]: string[];
  }): string | undefined => {
    return errors[key] ? errors[key][0] : undefined;
  };

  const hasErrors = (key: keyof {
    [K in NestedObjectPaths]: string[];
  }): boolean => {
    return getErrors(key) ? true : false;
  };

  const resetErrors = () => {
    for(const [key, _value] of Object.entries(errors)) {
      errors[key] = [];
    }
  };

  const toFormDataObject = () => {
    const formData = new FormData();

    for(const [key, value] of Object.entries(form)) {
      if(Array.isArray(value)) {
        value.forEach((innerValue) => {
          formData.append(`${key}[]`, innerValue);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    }

    return formData;
  };

  return {
    form,
    resetData,
    errors,
    setErrors,
    resetErrors,
    getErrors,
    setData,
    hasErrors,
    resetDirtyStatus,
    isDirty,
    toFormDataObject,
  };
}
