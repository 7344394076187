import { DateTime } from 'luxon';
import { Model } from 'pinia-orm';
import { Attr, BelongsTo, BelongsToMany, Bool, HasMany, MorphMany, Num, Str } from 'pinia-orm/dist/decorators';
import AssetType from './AssetType';
import Claim from './Claim';
import Comment from './Comment';
import Company from './Company';
import Labour from './Labour';
import Permissions from './Permissions';
import Plant from './Plant';
import PlantCategory from './PlantCategory';
import ProjectSupplier from './ProjectSupplier';
import Trade from './Trade';
import User from './User';
import VariationOrder from './VariationOrder';
import WbsCode from './WbsCode';
/* --- user header --- */
/* --- end user header --- */

export default class Project extends Model {
  static entity = 'App\\Models\\Project';
  // fields
  @Num(0)
  declare id: number;
  @Attr(undefined)
  declare company_id: number;
  @Str('')
  declare name: string;
  @Num(0)
  declare labour_standard_shift: number;
  @Num(0)
  declare plant_standard_shift: number;
  @Num(0)
  declare maximum_shift: number;
  @Num(0)
  declare commentary_lock: number;
  @Num(0)
  declare nov_period: number;
  @Bool(false)
  declare boq_locked: boolean;
  @Bool(false)
  declare feature_flag_progress_claims_lite: boolean;
  @Str('')
  declare deleted_at: string | undefined;
  @Str('')
  declare shift_start_at: string;
  @Str('')
  declare shift_end_at: string;
  @Str('')
  declare night_shift_start_at: string;
  @Str('')
  declare claim_start: string;
  @Str('')
  declare default_dashboard: string;
  @Str('')
  declare created_at: string | undefined;
  @Str('')
  declare updated_at: string | undefined;
  @Str('')
  declare boq_locked_at: string | undefined;
  @Str('')
  declare reference: string | undefined;
  @Str('')
  declare contractor_email: string | undefined;
  @Str('')
  declare contract_start_date: string | undefined;
  @Str('')
  declare contract_end_date: string | undefined;
  @Str('')
  declare pqs_email: string | undefined;
  @Str('')
  declare nov_clause: string | undefined;
  @Str('')
  declare superintendent_email: string | undefined;
  @Str('')
  declare client_email: string | undefined;
  @Str('')
  declare nod_clause: string | undefined;
  @Str('')
  declare description: string | undefined;
  @Str('')
  declare type: 'all' | 'contractor' | 'client';
  @Attr(undefined)
  declare wbs_codes: string[];
  @Attr(undefined)
  declare activities: string[];
  @Attr(undefined)
  declare elements: string[];
  @Attr(undefined)
  declare areas: string[];
  @Bool(false)
  declare track_weekends: boolean;
  // relations
  @BelongsToMany(() => User, () => Permissions, 'project_id', 'user_id', 'id', 'id')
  declare users: User[];
  @HasMany(() => Plant, 'project_id', 'id')
  declare plants: Plant[];
  @HasMany(() => Labour, 'project_id', 'id')
  declare labours: Labour[];
  @HasMany(() => WbsCode, 'project_id', 'id')
  declare codes: WbsCode[];
  @HasMany(() => VariationOrder, 'project_id', 'id')
  declare orders: VariationOrder[];
  @HasMany(() => VariationOrder, 'project_id', 'id')
  declare variation_orders: VariationOrder[];
  @HasMany(() => ProjectSupplier, 'project_id', 'id')
  declare suppliers: ProjectSupplier[];
  @HasMany(() => AssetType, 'project_id', 'id')
  declare asset_types: AssetType[];
  @HasMany(() => Claim, 'project_id', 'id')
  declare claims: Claim[];
  @HasMany(() => Trade, 'project_id', 'id')
  declare trades: Trade[];
  @BelongsTo(() => Company, 'company_id', 'id')
  declare company: Company;
  @HasMany(() => PlantCategory, 'project_id', 'id')
  declare plant_categories: PlantCategory[];
  @MorphMany(() => Comment, 'commentable_id', 'commentable_type', 'id')
  declare comments: Comment[];

  /* --- user code --- */

  get shiftStartAt(): Date {
    const shiftStart = this.shift_start_at.split(':');
    const date = new Date();

    date.setHours(parseInt(shiftStart[0]), parseInt(shiftStart[1]), parseInt(shiftStart[2]));

    return date;
  }

  get nightShiftStartAt(): Date {
    const shiftStart = this.night_shift_start_at.split(':');
    const date = new Date();

    date.setHours(parseInt(shiftStart[0]), parseInt(shiftStart[1]), parseInt(shiftStart[2]));

    return date;
  }

  get contractStartDate(): Date {
    return new Date(this.contract_start_date);
  }

  get contractEndDate(): Date {
    return new Date(this.contract_end_date);
  }

  get contractLength(): number {
    const start = DateTime.fromJSDate(this.contractStartDate).startOf('month');
    const end = DateTime.fromJSDate(this.contractEndDate).endOf('month');

    return Math.ceil(Math.abs(start.diff(end, ['months']).months));
  }

  get contractMonths(): DateTime[] {
    return Array.from({ length: this.contractLength }, (_, i) => {
      const date = DateTime.fromJSDate(this.contractStartDate).startOf('month');

      return date.plus({ months: i });
    });
  }

  get contractLengthInWeeks(): number {
    const start = DateTime.fromJSDate(this.contractStartDate);
    const end = DateTime.fromJSDate(this.contractEndDate);

    return Math.ceil(Math.abs(start.diff(end, ['weeks']).weeks));
  }

  get isClient(): boolean {
    return this.type === 'client';
  }

  get isContractor(): boolean {
    return this.type !== 'client';
  }

  estimateContractEndDate(days: number) {
    return DateTime.fromISO(this.contract_end_date).plus({ days });
  }
  /* --- end user code --- */
}
