import Company from '@/models/Company';
import Project from '@/models/Project';
import CompanyService from '@/services/CompanyService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useCompaniesStore = defineStore('companies', {
  state: () => ({}),

  getters: {
    models: () => useRepo(Company),
  },

  actions: {
    async fetchAdminIndex(page = 1) {
      const [data, error] = await CompanyService.adminIndex(page);

      if(data) {
        this.models.flush();
        this.models.save(data.companies.data);

        return data;
      }

      throw error;
    },

    async fetchAdminCompany(id: Id) {
      const [data, error] = await CompanyService.adminShow(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateAdminCompany(id: Id, request: ApiRequests['api.v1.admin.companies.update']) {
      const [data, error] = await CompanyService.adminUpdate(id, request);

      if(data) {
        const projectRepo = useRepo(Project);

        projectRepo.flush();
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async createCompany(request: ApiRequests['api.v1.admin.companies.store']) {
      const [data, error] = await CompanyService.create(request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateCompany(id: Id, request: ApiRequests['api.v1.companies.update']) {
      const [data, error] = await CompanyService.update(id, request);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async deleteCompany(id: Id) {
      const [data, error] = await CompanyService.delete(id);

      if(data) {
        this.models.destroy(id);

        return data;
      }

      throw error;
    },
  },
});
