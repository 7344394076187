import { route } from '@/ziggy-shim';
import { api, apiConfig, CastValue } from './LaravelClient';

export default {
  async index() {
    return await api(route('api.v1.projects.index'), 'get');
  },

  async show(id: Id) {
    return await api(route('api.v1.projects.show', id), 'get');
  },

  async create(data: ApiRequests['api.v1.projects.store']) {
    return await api(route('api.v1.projects.store'), 'post', {
      data,
      casts: {
        activities: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        areas: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        elements: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        wbs_codes: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async companyCreate(companyId: Id, data: ApiRequests['api.v1.company.projects.store']) {
    return await api(route('api.v1.company.projects.store', companyId), 'post', {
      data,
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.projects.update']) {
    return await api(route('api.v1.projects.update', id), 'patch', {
      data,
      casts: {
        reference: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        default_dashboard: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async updateHeadContractSettings(id: Id, data: ApiRequests['api.v1.projects.head-contract-settings']) {
    return await api(route('api.v1.projects.head-contract-settings', id), 'patch', {
      data,
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.projects.destroy', id), 'delete');
  },

  async monthlyReport(id: Id) {
    return await api(route('api.v1.projects.monthly-report', id), 'post');
  },

  async wbsCodeAverage(id: Id, data: ApiRequests['api.v1.projects.wbs_code.average']) {
    return await api(route('api.v1.projects.wbs_code.average', id), 'get', {
      data,
    });
  },

  async utilisationReport(id: Id, data: ApiRequests['api.v1.projects.plant-utilisation']) {
    return await api(route('api.v1.projects.plant-utilisation', id), 'get', {
      data,
    });
  },

  async plantUtilisationGraph(
    id: Id,
    data: ApiRequests['api.v1.projects.plant-utilisation-metrics'] = { date: undefined },
  ) {
    return await api(route('api.v1.projects.plant-utilisation-metrics', id), 'get', {
      data,
    });
  },

  async selfPerformMetrics(id: Id, data: ApiRequests['api.v1.projects.self-perform-metrics'] = { date: undefined }) {
    return await api(route('api.v1.projects.self-perform-metrics', id), 'get', {
      data,
    });
  },

  async costAggregationSummary(id: Id) {
    return await api(route('api.v1.projects.cost-aggregation', id), 'get');
  },

  async weeklyPerformanceGraph(id: Id) {
    return await api(route('api.v1.projects.weekly-performance-metrics', id), 'get');
  },

  async generate() {
    return await api(route('api.v1.projects.generate'), 'post');
  },

  async adminIndex(page = 1) {
    return await apiConfig(route('api.v1.admin.projects.index'), 'get', {
      params: {
        page,
      },
    });
  },

  async lockBoq(id: Id) {
    return await api(route('api.v1.projects.lock-boq', id), 'post');
  },

  async unlockBoq(id: Id) {
    return await api(route('api.v1.projects.unlock-boq', id), 'post');
  },

  coverPage: {
    async store(id: Id, data: ApiRequests['api.v1.projects.cover-page.store'] | FormData) {
      return await api(route('api.v1.projects.cover-page.store', id), 'post', {
        data,
      });
    },

    async show(id: Id) {
      return await api(route('api.v1.projects.cover-page.show', id), 'get');
    },
  },

  commentary: {
    async index(id: Id) {
      return await api(route('api.v1.projects.commentary.index', id), 'get');
    },

    async store(id: Id, data: ApiRequests['api.v1.projects.commentary.store']) {
      return await api(route('api.v1.projects.commentary.store', id), 'post', {
        data,
      });
    },

    async update(projectId: Id, commentId: Id, data: ApiRequests['api.v1.projects.commentary.update']) {
      return await api(route('api.v1.projects.commentary.update', [projectId, commentId]), 'patch', {
        data,
      });
    },

    async show(projectId: Id, commentId: Id) {
      return await api(route('api.v1.projects.commentary.show', [projectId, commentId]), 'get');
    },
  },

  admin: {
    async search(data: ApiRequests['api.v1.admin.projects.search']) {
      return await api(route('api.v1.admin.projects.search'), 'get', {
        data,
        casts: {
          name: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        },
      });
    },

    async update(id: Id, data: ApiRequests['api.v1.admin.projects.update']) {
      return await api(route('api.v1.admin.projects.update', id), 'patch', {
        data,
        casts: {
          reference: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
          default_dashboard: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        },
      });
    },

    async forceDelete(id: Id) {
      return await api(route('api.v1.admin.projects.force-delete', id), 'delete');
    },

    async restore(id: Id) {
      return await api(route('api.v1.admin.projects.restore', id), 'patch');
    },
  },
};
