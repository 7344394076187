import { route } from '@/ziggy-shim';
import AxiosClient from './AxiosClient';
import { api, apiConfig, CastValue } from './LaravelClient';

export default {
  async index(projectId: Id, data: ApiRequests['api.v1.variation-orders.index']) {
    return await api(route('api.v1.variation-orders.index', projectId), 'get', {
      data,
      casts: {
        search: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        status: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async claimIndex(projectId: Id) {
    return await api(route('api.v1.variation-orders.claim-index', projectId), 'get');
  },

  async show(id: Id) {
    return await api(route('api.v1.variation-orders.show', id), 'get');
  },

  async accept(id: Id) {
    return await api(route('api.v1.variation-orders.accept', id), 'post');
  },

  async decline(id: Id) {
    return await api(route('api.v1.variation-orders.decline', id), 'post');
  },

  async price(id: Id, data: ApiRequests['api.v1.variation-orders.pricing']) {
    return await api(route('api.v1.variation-orders.pricing', id), 'post', {
      data,
    });
  },

  async submit(id: Id) {
    return await api(route('api.v1.variation-orders.submit', id), 'post');
  },

  async complete(id: Id) {
    return await api(route('api.v1.variation-orders.complete', id), 'post');
  },

  async provision(id: Id, signedParams: unknown) {
    return await apiConfig(route('api.v1.variation-orders.provision', id), 'post', {
      params: signedParams,
      paramsSerializer: {
        serialize: (params) => {
          return new URLSearchParams(params).toString();
        },
      },
    });
  },

  async approve(id: Id) {
    return await api(route('api.v1.variation-orders.approve', id), 'post');
  },

  async create(projectId: Id, data: ApiRequests['api.v1.variation-orders.store'] | FormData) {
    return await api(route('api.v1.variation-orders.store', projectId), 'post', {
      data,
      casts: {
        notes: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async update(id: Id, data: ApiRequests['api.v1.variation-orders.update']) {
    return await api(route('api.v1.variation-orders.update', id), 'patch', {
      data,
      casts: {
        notes: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        client_ref: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
        next_action: (value, clone, key) => new CastValue(value).cast('nullable', clone, key).get(),
      },
    });
  },

  async delete(id: Id) {
    return await api(route('api.v1.variation-orders.destroy', id), 'delete');
  },

  async summary(projectid: Id) {
    return await api(route('api.v1.variation-orders.summary', projectid), 'get');
  },

  async comment(id: Id, data: ApiRequests['api.v1.variation-orders.comments']) {
    return await api(route('api.v1.variation-orders.comments', id), 'post', {
      data,
    });
  },

  async sendNotice(id: Id) {
    return await api(route('api.v1.variation-orders.send-notice', id), 'post');
  },

  async media(id: Id) {
    return await api(route('api.v1.variation-orders.media.show', id), 'get');
  },

  async storeMedia(id: Id, data: ApiRequests['api.v1.variation-orders.media.store'] | FormData) {
    return await api(route('api.v1.variation-orders.media.store', id), 'post', {
      data,
    });
  },

  notice: {
    async store(id: Id, data: ApiRequests['api.v1.variation-orders.notice.store'] | FormData) {
      return await api(route('api.v1.variation-orders.notice.store', id), 'post', {
        data,
      });
    },
  },

  pricingNotice: {
    async store(id: Id, data: ApiRequests['api.v1.variation-orders.pricing-notice.store'] | FormData) {
      return await api(route('api.v1.variation-orders.pricing-notice.store', id), 'post', {
        data,
      });
    },
  },

  correspondence: {
    async index(id: Id) {
      return await api(route('api.v1.variation-orders.correspondence.index', id), 'get');
    },

    async store(id: Id, data: ApiRequests['api.v1.variation-orders.correspondence.store'] | FormData) {
      return await api(route('api.v1.variation-orders.correspondence.store', id), 'post', {
        data,
      });
    },
  },
};
